import React, { Fragment, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import emailjs from '@emailjs/browser'
import './ContactForm.css'

export default function ContactForm() {
  const initialState = { name: '', email: '', message: '' }
  const [eachEntry, setEachEntry] = useState(initialState)
  const [isWorking, setIsWorking] = useState(false)
  const { name, email, message } = eachEntry

  const handleOnChange = (e: { target: { name: any; value: any } }) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value })
  }

  const submitContact = (e: any) => {
    e.preventDefault()
    setIsWorking(true)
    emailjs
      .send(
        'service_3r7nznk',
        'template_3xyki8e',
        {
          name,
          message,
          email,
        },
        {
          publicKey: '9_z9u014t2h62bEBZ',
        },
      )
      .then(
        () => {
          toast.success('Thank you, your message has been sent.')
          setEachEntry({ name: '', message: '', email: '' })
          setIsWorking(false)
        },
        (error) => {
          toast.error(
            'There was an issue sending your message.  Please reach out to tracy.witkosky@growthalc.com for assistance. ',
            error.message,
          )
          setIsWorking(false)
        },
      )
  }

  return (
    <Fragment>
      <Container fluid className='m-0 p-0 contactImage'></Container>
      <Row>
        <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }}>
          <h1 className='my-3'>Contact Us</h1>
          <p>
            If you're interested in learning more about our services or have questions, please
            contact us through the form below.
          </p>
          <Form>
            <Form.Group controlId='name' className='my-3'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                value={name}
                name='name'
                type='text'
                placeholder='First Last'
              />
            </Form.Group>
            <Form.Group controlId='email' className='my-3'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                value={email}
                name='email'
                type='text'
                placeholder='youremail@domain.com'
              />
            </Form.Group>
            <Form.Group controlId='message' className='my-3'>
              <Form.Label>Message</Form.Label>
              <Form.Control
                onChange={handleOnChange}
                value={message}
                name='message'
                as='textarea'
                rows={6}
                placeholder='Type your message here.'
              />
            </Form.Group>
            <Button
              variant='primary'
              type='submit'
              className='my-3'
              onClick={submitContact}
              disabled={isWorking}
            >
              Submit
            </Button>
            <ToastContainer position='top-right' autoClose={5000} />
          </Form>
        </Col>
      </Row>
    </Fragment>
  )
}
