import React, { Fragment } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Facebook, Instagram, Youtube } from 'react-bootstrap-icons'
import Logo from '../../assets/Logo/Favicon/Wordpress.png'
import './Navigation.css'

export default function Footer() {
  return (
    <Fragment>
      <footer className='d-flex flex-wrap justify-content-between align-items-center py-3 mt-auto border-top'>
        <Container fluid>
          <Row>
            <Col md={4} className='d-flex align-items-center'>
              <a
                href='/'
                className='mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1'
              >
                <Image src={Logo} alt='Logo' height={62}></Image>
              </a>
              <span className='mb-3 mb-md-0 text-body-secondary'>
                © 2025 Growth Learning Academy Center, LLC
              </span>
            </Col>
            <Col md={{ span: 4, offset: 4 }} className='ms-auto'>
              <ul className='nav justify-content-end list-unstyled d-flex'>
                <li className='ms-3 mt-1'>
                  <a
                    className='text-body-secondary'
                    href='https://www.instagram.com/hollyrwilson_mga/'
                  >
                    <Instagram size='36'></Instagram>
                  </a>
                </li>
                <li className='ms-3 mt-1'>
                  <a
                    className='text-body-secondary'
                    href='https://www.facebook.com/profile.php?id=61562699986371'
                  >
                    <Facebook size='36'></Facebook>
                  </a>
                </li>
                <li className='ms-3 mt-1'>
                  <a
                    className='text-body-secondary'
                    href='https://www.youtube.com/@GrowthAcademyLearningCenter'
                  >
                    <Youtube size='36'></Youtube>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  )
}
